<template>
  <div
    class="bg-gray-350"
    :class="{
      'py-10 lg:py-20': theme === 'default',
      'py-10 lg:py-16': theme === 'v2',
      'py-0 bg-transparent': theme === 'minimal'
    }"
  >
    <div
      class="mx-auto"
      :class="maxWidth"
    >
      <p
        v-if="$slots['header']"
        class="px-4 lg:px-0"
        :class="[
          {
            'md:text-center': isCenter,
            'text-3.5xl lg:text-4.5xl mb-8': theme === 'default',
            'text-2xl md:text-3.5xl/12 mb-6 md:mb-10': theme === 'v2'
          },
          titleClass
        ]"
      >
        <slot name="header"></slot>
      </p>

      <p
        v-if="$slots['sub-header']"
        class="text-lg mb-6 md:mb-8 md:text-center text-left px-4 lg:px-0"
        :class="[
          {
            'lg:text-center': isCenter
          },
          subTitleClass
        ]"
      >
        <slot name="sub-header"></slot>
      </p>

      <client-only>
        <component
          :is="component.wrapper.main"
          ref="swiper"
          v-bind="component.wrapper.props"
          :class="component.wrapper?.style"
        >
          <component
            :is="component.wrapper.card"
            v-for="company in companies"
            :key="company.company || company"
            class="box-border"
            :class="[
              component.card,
              {
                '!p-0': company?.company?.includes('Peoplecare'),
                'relative flex flex-col cursor-pointer border border-white hover:border-success shadow-sm-1': haveLink && theme === 'minimal',
                'relative flex flex-col': !haveLink && theme === 'minimal',
                'h-[10.5rem] md:h-[12rem]': theme === 'minimal' && company?.content,
                'justify-center': theme === 'minimal' && !company?.content,
              }
            ]"
            v-on="{
              ...((company?.url && {
                click: () => handleClickUrl(company?.url)
              }) || {})
            }"
          >
            <IconsCheckedBox
              v-if="company?.active"
              type="circle"
              class="absolute top-2 right-2"
              fill="#0AC16A"
            />
            <img
              :src="company.image || `${(company.assetsBaseUrl) || assetsBaseUrl}${(company.company || company)}`"
              :alt="company.title || (company.company || company).replace(/.(svg|png|jpg|jpeg|webp|gif)$/g, '').replace(/(-|_)/gm, ' ')"
              :width="(company.dimensions && company.dimensions.width) || 48"
              :height="(company.dimensions && company.dimensions.height) || 48"
              :loading="imageLazyLoad ? 'lazy' : 'eager'"
              :class="{
                'm-auto': !haveLink && !company?.content,
                'mx-auto h-12 min-h-12 w-auto max-w-[50%]': haveLink || company?.content
              }"
            />
            <!-- eslint-disable vue/no-v-html -->
            <p
              v-if="company?.content"
              class="mt-4 mb-0 text-xs md:text-sm text-center"
              v-html="company?.content"
            ></p>
          </component>

          <component
            :is="component.wrapper.card"
            v-if="showMore"
            class="box-border"
            :class="component.card"
          >
            <atoms-button
              class="!p-0"
              size="small"
              theme="tertiary"
              :to="moreButtonUrl"
              full
            >
              + MORE
            </atoms-button>
          </component>
        </component>
      </client-only>
    </div>
  </div>
</template>

<script setup>
import { register } from 'swiper/element/bundle'

register()

defineOptions({
  name: 'HomeCompanyComparison'
})

const props = defineProps({
  theme: {
    type: String,
    default: 'default',
    validator: str => ['default', 'v2', 'minimal'].includes(str)
  },

  isCenter: {
    type: Boolean,
    default: false
  },

  companies: {
    type: Array,
    required: true
  },

  moreButtonUrl: {
    type: String,
    default: null
  },

  showMore: {
    type: Boolean,
    default: true
  },

  assetsBaseUrl: {
    type: String,
    default: `${APP_ASSET_BASEURL}edge/static/images/company-logos/`
  },

  containerClass: {
    type: String,
    default: 'grid-cols-3 md:grid-cols-6'
  },

  maxWidth: {
    type: String,
    default: 'max-w-wrapper-md'
  },

  titleClass: {
    type: String,
    default: ''
  },

  subTitleClass: {
    type: String,
    default: ''
  },

  imageLazyLoad: {
    type: Boolean,
    default: false
  },

  isCarousel: {
    type: Boolean,
    default: false
  },

  carouselClass: {
    type: String,
    default: ''
  },

  haveLink: {
    type: Boolean,
    default: false
  }
})

const component = computed(() => {
  const wrapper = {
    main: props.isCarousel
      ? 'swiper-container'
      : 'div',
    card: props.isCarousel
      ? 'swiper-slide'
      : 'div',
    style: getKey(props.theme, {
      default: `grid gap-4 ${props.containerClass}`,
      v2: '',
      minimal: 'grid gap-4 grid-cols-2 md:grid-cols-4 px-4 md:px-0'
    }),
    props: !props.isCarousel
      ? ''
      : {
        'slides-per-view': 'auto',
        init: 'false',
        style: {
          '--swiper-pagination-bottom': '0'
        },
        class: props.carouselClass
      }
  }

  const card = (() => {
    const size = getKey(props.theme, {
      default: 'h-[117px] md:h-[122px] lg:h-[140px]',
      v2: 'exact-wh-[6.375rem] md:exact-w-[7.4285714286rem] md:exact-h-[7.5rem]',
      minimal: ''
    })

    const main = getKey(props.theme, {
      default: 'flex bg-white p-5 rounded-lg',
      v2: 'bg-white p-5 border-1 border-gray-400 rounded-2.5xl',
      minimal: 'flex bg-white p-4 rounded-2.5xl'
    })

    return `${size} ${main}`
  })()

  return {
    wrapper,
    card
  }
})

const swiper = ref()

const initializeSwiper = () => {
  const el = swiper.value

  if (el) {
    const params = {
      injectStyles: [`
        :host .swiper-pagination {
          height: 8px;
        }

        :host .swiper-pagination-bullet-active {
          background-color: #475D80 !important;
        }

        @media only screen and (max-width: 1023px) {
          :host .swiper-pagination-bullet {
            position: relative;
            top: -9px;
          }

          :host .swiper {
            padding: 0 16px;
            width: calc(100% - 32px);
          }
        }

        @media only screen and (max-width: 767px) {
          :host .swiper {
            height: 136px;
          }
        }

        @media only screen and (min-width: 768px) and (max-width: 1023px) {
          :host .swiper {
            height: 152px;
          }
        }
      `],
      pagination: {
        clickable: true
      },
      spaceBetween: 16
    }

    Object.assign(el, params)
    el.initialize()
  }
}

watch(swiper, (newVal, oldVal) => {
  if (props.isCarousel && newVal !== undefined && oldVal === undefined) {
    initializeSwiper()
  }
})

const handleClickUrl = url => {
  if (url) {
    window.location = url
  }
}
</script>
